// NOTE:
// 1. Every route has to ends with "/". If not - navigation link won't be set as active on page refresh.
// 2. Check if there is something to change in gatsby-plugin-sitemap in gatsby-config.

const careersEnabled = {
    list: true,
    javascript: false,
    android: true,
    iOS: false,
    python: true,
    digitalMarketingSpecialist: false,
    salesAdmin: true,
    internationalBusinessDevelopmentManagerDirectOperations: true,
    internationalBusinessDevelopmentManagerIndirectOemOperations: false,
    devOps: false
};

const careersRoute = '/careers/';
const solutionsRoute = '/solutions/';
const technologyRoute = '/technology/';
const sawmillPageRoute = '/sawmill/';
const inspirationsPageRoute = '/inspirations/';
const optiwaiPlusPageRoute = '/optiwaiplus/';
const traceabilityPageRoute = '/traceability/';

const routes = {
    home: {
        route: '/',
        sections: {
            timberId: {
                anchor: 'timber-id',
            },
            technologyPartner: {
                title: 'Technology Partner',
                anchor: 'technology-partner',
            },
        }
    },
    news: {
        pageTitle: "News",
        route: '/news/', // NOTE: the same as siteMetadata.newsListUrl in gatsby-config.js
    },
    LegalDocuments: {
        pageTitle: "Legal Documents",
        route: '/legal_documents/',
        excludeFromSitemap: true,
    },
    CookieSettings: {
        pageTitle: "Cookie Settings",
        route: '/cookie_settings/',
        excludeFromSitemap: true,
    },
    PrivacyPolicy: {
        pageTitle: "Privacy Policy",
        route: '/privacy_policy/',
        excludeFromSitemap: true,
    },
    EuropeanFunds: {
        pageTitle: "European Funds",
        route: '/european_funds/'
    },
    Contact: {
        pageTitle: "Contact",
        route: '/contact/'
    },
    Partnership: {
        pageTitle: "Partnership",
        route: '/partnership/',
    },
    About: {
        pageTitle: "About",
        route: '/about/',
    },
    Careers: {
        pageTitle: "Careers",
        route: careersRoute,
        anchor: 'careers',
    },
    CareersJavascriptDevelopers: {
        pageTitle: "JavaScript Developer",
        route: `${careersRoute}javascript_developer/`,
        excludeFromSitemap: !careersEnabled.javascript
    },
    CareersAndroidDeveloper: {
        pageTitle: "Junior Android Developer",
        route: `${careersRoute}android_developer/`,
        excludeFromSitemap: !careersEnabled.android
    },
    CareersIOSDeveloper: {
        pageTitle: "iOS Developer",
        route: `${careersRoute}ios_developer/`,
        excludeFromSitemap: !careersEnabled.iOS
    },
    CareersPythonDeveloper: {
        pageTitle: " Backend Python Developer",
        route: `${careersRoute}python_developer/`,
        excludeFromSitemap: !careersEnabled.python
    },
    CareersDigitalMarketingSpecialist: {
        pageTitle: "Digital Marketing Specialist",
        route: `${careersRoute}digital_marketing_specialist/`,
        excludeFromSitemap: !careersEnabled.digitalMarketingSpecialist
    },
    CareersSalesAdmin: {
        pageTitle: "Sales Admin",
        route: `${careersRoute}sales_admin/`,
        excludeFromSitemap: !careersEnabled.salesAdmin
    },
    CareersManagerDirectOperations: {
        pageTitle: "International Business Development Manager – Direct Operations",
        route: `${careersRoute}international_business_development_manager_direct_operations/`,
        excludeFromSitemap: !careersEnabled.internationalBusinessDevelopmentManagerDirectOperations
    },
    CareersManagerIndirectOEMOperations: {
        pageTitle: "International Business Development Manager – Indirect, OEM Operations",
        route: `${careersRoute}international_business_development_manager_indirect_oem_operations/`,
        excludeFromSitemap: !careersEnabled.internationalBusinessDevelopmentManagerIndirectOemOperations
    },
    CareersDevOps: {
        pageTitle: "DevOps Engineer",
        route: `${careersRoute}dev_ops/`,
        excludeFromSitemap: !careersEnabled.devOps
    },

    Technology: {
        pageTitle: "Technology",
        route: technologyRoute,
        sections: {
            saas: {
                title: 'SaaS Platform',
                anchor: 'saas',
            },
            mobileApps: {
                title: "Mobile Apps",
                anchor: 'mobile-apps',
                subSections: {
                    dcodeReader: {
                        title: 'Dcode Reader',
                        anchor: 'dcode-reader'
                    },
                }
            },
            markers: {
                title: "Markers",
                anchor: 'markers',
            },
            electronicDevices: {
                title: "Electronic Devices",
                anchor: 'electronic-devices',
            },
        }
    },
    TechnologyEDG: {
        pageTitle: "Electronic Dcode Generator",
        route: `${technologyRoute}edg/`,
    },
    TechnologyODR: {
        pageTitle: "Optical Dcode Reader",
        route: `${technologyRoute}odr/`,
    },
    TechnologyDMH: {
        pageTitle: "Dcode Marker Hammer",
        route: `${technologyRoute}dmh/`,
    },
    TechnologyDMS: {
        pageTitle: "Dcode Marker Stamp",
        route: `${technologyRoute}dms/`,
    },
    TechnologyDMASAutomated: {
        pageTitle: "Dcode Marker Automated - S Automated",
        route: `${technologyRoute}dma_s_automated/`,
    },
    TechnologyDMASAAutomated: {
        pageTitle: "Dcode Marker Automated - S/A Automated",
        route: `${technologyRoute}dma_s_a_automated/`,
    },
    TechnologySaaS: {
        pageTitle: "SaaS",
        route: `${technologyRoute}saas/`,
    },
    TechnologyMobileApps: {
        pageTitle: "Mobile Apps",
        route: `${technologyRoute}mobile_apps/`,
    },
    TechnologyMobileAppsDcodeGallery: {
        pageTitle: "Dcode Gallery",
        route: `${technologyRoute}mobile_apps/dcode_gallery/`,
    },


    Platform: {
        pageTitle: "Platform",
        route: '/platform/',
        sections: {
            dueDiligenceStatementCreation: {
                title: 'Due Diligence Statement Creation',
                anchor: 'due-diligence-statement-cCreation',
            },
            transactionDDSNumber: {
                title: 'Transaction DDS Number',
                anchor: 'transaction-DDS-number',
            },
            deforestationGeoSpatialData: {
                title: 'Deforestation Geo-Spatial Data',
                anchor: 'deforestation-geo-spatial-data',
            },
            digitalDeliveryNote: {
                title: "Digital Delivery Note",
                anchor: 'digital-delivery-note',
            },
            timberIDAuthentication: {
                title: "TimberID Authentication",
                anchor: 'timberID-authentication',
            },
            timberIDBusinessIntelligenceReporting: {
                title: "TimberID Business Intelligence & Reporting",
                anchor: 'timberID-business-intelligence-reporting',
            },
        }
    },

    Offering: {
        pageTitle: "Offering",
        route: '/offering/',
        sections: {
            offeringSection: {
                title: 'Offering',
                anchor: 'offering',
            },
            pricingModel: {
                title: 'Pricing Model',
                anchor: 'pricing-Model',
            },
            saas: {
                title: "SaaS Platform",
                anchor: 'saas-platform',
            },
            mobileApps: {
                title: "Mobile Apps",
                anchor: 'mobile-apps',
            },
            hardware: {
                title: "Hardware",
                anchor: 'hardware',
            },
        }
    },
    OfferingDcodeApplyAndRead: {
        pageTitle: "Dcode - Apply and read",
        route: `/offering/dcode_apply_and_read/`,
    },
    OfferingTimberIDAuthenticationPlatform: {
        pageTitle: "TimberID Authentication Platform",
        route: `/offering/timberID_authentication_platform/`,
    },
    OfferingTimberIDEUDRPlatform: {
        pageTitle: "TimberID EUDR Platform",
        route: `/offering/timberID_EUDR_platform/`,
    },


    Traceability: {
        pageTitle: "Traceability",
        route: traceabilityPageRoute,
        sections: {
            measurement: {
                title: "Measurement",
                anchor: 'measurement',
            },
            marking: {
                title: "Marking",
                anchor: 'marking',
            },
            inventory: {
                title: "Inventory",
                anchor: 'inventory',
            },
            logistic: {
                title: "Logistics",
                anchor: 'logistics',
            },
            sawmillTrader: {
                title: "Sawmill, Trader",
                anchor: 'sawmill_trader',
            },
            forest: {
                title: "Forest",
                anchor: 'forest',
            },
            forestStreet: {
                title: "Forest Street",
                anchor: 'forest_street',
            },
            sawmillTrader: {
                title: "Sawmill, Trader",
                anchor: 'sawmill_trader',
            },
            atTheSawmill: {
                title: "at_the_sawmill",
                anchor: 'at_the_sawmill',
            },
        }
    },
    TraceabilitySawmillTrader: {
        pageTitle: "Sawmill, Trader",
        route: `${traceabilityPageRoute}sawmill_trader/`,
    },
    TraceabilityAtTheSawmill: {
        pageTitle: "At the Sawmill",
        route: `${traceabilityPageRoute}at_the_sawmill/`,
    },
    TraceabilityForest: {
        pageTitle: "Forest",
        route: `${traceabilityPageRoute}forest/`,
    },
    TraceabilityForestStreet: {
        pageTitle: "Forest Street",
        route: `${traceabilityPageRoute}forest_street/`,
    },
    TraceabilityLogistics: {
        pageTitle: "Logistics",
        route: `${traceabilityPageRoute}logistics/`,
    },
    Eudr: {
        pageTitle: "EUDR",
        route: '/eudr/',
    }
};

const historicalRoutes = {
    UseCases: {
        pageTitle: "Use Cases",
        route: '/use_cases/',
    },
    Support: {
        pageTitle: "Support",
        route: '/support/',
    },
    UserGuide: {
        pageTitle: "User Guide",
        route: `/user_guide/`,
    },
    Integrations: {
        pageTitle: "Integrations",
        route: `/integrations/`,
    },
    Inspirations: {
        pageTitle: "Inspirations",
        route: inspirationsPageRoute,
    },
    InspirationsAbout: {
        route: `${inspirationsPageRoute}about/`,
    },
    InspirationsCars: {
        route: `${inspirationsPageRoute}cars/`,
    },
    InspirationsDatingSites: {
        route: `${inspirationsPageRoute}dating_sites/`,
    },
    InspirationsEcommerce: {
        route: `${inspirationsPageRoute}ecommerce/`,
    },
    InspirationsPremiumBrands: {
        route: `${inspirationsPageRoute}premium_brands/`,
    },
    InspirationsRealEstate: {
        route: `${inspirationsPageRoute}real_estate/`,
    },
    InspirationsRestoration: {
        route: `${inspirationsPageRoute}restoration/`,
    },
    InspirationsSharing: {
        route: `${inspirationsPageRoute}sharing/`,
    },
    InspirationsWebPublishing: {
        route: `${inspirationsPageRoute}web_publishing/`,
    },
    Optiwai: {
        pageTitle: "OptiwAI",
        route: `/optiwai/`,
    },
    OptiwaiPlus: {
        pageTitle: "OptiwAI+",
        route: optiwaiPlusPageRoute,
    },
    OptiwaiPlusAbout: {
        route: `${optiwaiPlusPageRoute}about/`,
    },
    OptiwaiPlusCampaign: {
        route: `${optiwaiPlusPageRoute}campaign/`,
    },
    OptiwaiPlusExplore: {
        route: `${optiwaiPlusPageRoute}explore`,
    },
    OptiwaiPlusIntegrations: {
        route: `${optiwaiPlusPageRoute}integrations`,
    },
    OptiwaiPlusPricing: {
        route: `${optiwaiPlusPageRoute}pricing`,
    },
    OptiwaiPlusUserGuide: {
        route: `${optiwaiPlusPageRoute}user_guide`,
    },
    Explore: {
        pageTitle: "Explore",
        route: `${solutionsRoute}explore/`,
    },
    Pricing: {
        pageTitle: "Pricing",
        route: `/pricing/`,
    },
    RealEstate: {
        pageTitle: "Real Estate",
        route: `/realestate/`,
    },
    Campaign: {
        pageTitle: "Campaign",
        route: `${solutionsRoute}campaign/`,
    },
    Dcode: {
        pageTitle: "Dcode",
        route: '/dcode/',
    },
    MarkingsGallery: {
        pageTitle: "Markings Gallery",
        route: `/forestry/markings_gallery/`,
    },
    Forestry: {
        pageTitle: "Forestry",
        route: '/forestry/',
    },
    ForestryExplore: {
        pageTitle: "Explore",
        route: `/forestry/explore/`,
    },
    Sawmill: {
        pageTitle: "Sawmill",
        route: sawmillPageRoute
    },
    SawmillInventory: {
        pageTitle: "Log Yard Inventory Management",
        route: `${sawmillPageRoute}inventory/`,
    },
    SawmillBoard: {
        pageTitle: "Timber Provenance at Board Level",
        route: `${sawmillPageRoute}board/`,
    },
    SawmillEntryDataCollection: {
        pageTitle: "Entry Data Collection",
        route: `${sawmillPageRoute}entry_data/`,
    },
    Solutions: {
        pageTitle: "Solutions",
        route: solutionsRoute,
        sections: {
            saas: {
                title: 'SaaS Platform',
                anchor: 'saas',
            },
            mobileApps: {
                title: "Mobile Apps",
                anchor: 'mobile-apps',
            },
            dcodeMarking: {
                title: "Markers",
                anchor: 'dcode-marking',
            },
        }
    },
    SolutionsEDG: {
        pageTitle: "Electronic Dcode Generator",
        route: `${solutionsRoute}edg/`,
    },
    SolutionsODR: {
        pageTitle: "Optical Dcode Reader",
        route: `${solutionsRoute}odr/`,
    },
    SolutionsDMH: {
        pageTitle: "Dcode Marker Hammer",
        route: `${solutionsRoute}dmh/`,
    },
    SolutionsDMA: {
        pageTitle: "Dcode Marker Automated",
        route: `${solutionsRoute}dma/`,
    },
    SolutionsSaaS: {
        pageTitle: "SaaS",
        route: `${solutionsRoute}saas/`,
    },
    SolutionsMobileApps: {
        pageTitle: "Mobile Apps",
        route: `${solutionsRoute}mobile_apps/`,
    },
    SolutionsMobileAppsDcodeGallery: {
        pageTitle: "Dcode Gallery",
        route: `${solutionsRoute}mobile_apps/dcode_gallery/`,
    },
};

const getRoutes = () => {
    return routes;
};

const mapHistoricalRoutesToExcludeInSitemap = () => Object.values(historicalRoutes).map(value => value.route);
const mapRoutesToExcludeInSitemap = () => Object.values(routes).filter(value => value.excludeFromSitemap).map(value => value.route);

const legalDocuments = {
    deeplai: {
        privacyPolicy: '/files/Deeplai_Privacy_Policy.pdf'
    }
};

// TODO
const getLink = (routesArray = [], sectionWithPage = false) => {
    const indexRoute = routes.home.route;

    const getPage = () => {
        const page = routesArray[0];
        if (!page) {
            console.error('! route not found: ', routesArray[0]);
        }
        return page;
    };

    switch (routesArray.length) {
        case 1: {
            return getPage().route;
        }
        case 2: {
            const page = getPage();
            const section = routesArray[1];
            return sectionWithPage ? `${page.route}#${section}` : `#${section}`;
        }
        default:
            return indexRoute;
    }
};

const getId = route => {
    const defaultId = '';
    if (typeof route !== 'string') {
        return defaultId;
    }

    const getPage = () => routes[routesArray[0]];
    const getSection = page => page.sections[routesArray[1]];

    const routesArray = route.split('.');
    if (routesArray.length !== 2) {
        return defaultId;
    }
    const page = getPage();
    return getSection(page);
};

module.exports = {
    getRoutes,
    legalDocuments,
    careersEnabled,
    getLink,
    getId,
    mapRoutesToExcludeInSitemap,
    mapHistoricalRoutesToExcludeInSitemap
};